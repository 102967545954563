.card-contributor {
  &-desktop {
    display: flex;
    .section-image {
      padding: 1rem;
      display: flex;
      justify-content: center;
      .image-contributor {
        object-fit: cover;
        object-position: center center;
        width: 3rem;
        height: 3rem;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
        border-radius: 50%;
      }
    }
    .section-content {
      flex: 1;
      .content-top {
        display: flex;
        justify-content: space-between;
        flex: 1;
        width: 100%;

        .info-personal {
          display: flex;
          flex-direction: column;
          font-weight: 500;
          .name {
            width: 100%;
            font-weight: bold;
            font-size: $font-size-4;
          }
        }
        .amount {
          color: $secondary-color;
          font-family: $secondary-font;
          font-size: $font-size-2;
        }
      }
    }
    .text-content {
      .message {
        font-size: $font-size-5;
        font-weight: 500;
      }
    }
    &:not(:last-child) {
      border-bottom: 1px rgba($gray, 0.1) solid;
    }
  }

  &-mobile {
    .section-header {
      display: flex;
    }
    .section-image {
      display: flex;
      padding-right: 1rem;
      justify-content: center;
      .image-contributor {
        object-fit: cover;
        object-position: center center;
        width: 3rem;
        height: 3rem;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
        border-radius: 50%;
      }
    }
    .section-content {
      flex: 1;
      .content-top {
        display: flex;
        justify-content: space-between;
        flex: 1;
        width: 100%;

        .info-personal {
          display: flex;
          flex-direction: column;
          font-weight: 500;
          .name {
            width: 100%;
            font-weight: bold;
            font-size: $font-size-4;
          }
        }
        .amount {
          color: $secondary-color;
          font-family: $secondary-font;
          font-size: $font-size-2;
        }
      }
    }
    .text-content {
      .message {
        font-size: $font-size-5;
        font-weight: 500;
      }
    }
    &:not(:last-child) {
      border-bottom: 1px rgba($gray, 0.1) solid;
    }
  }
}
