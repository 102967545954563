.enrichher {
  &-input {
    outline: none;
    border: solid 1px $gray;
    color: $gray;
    max-width: 15rem !important;
    border-radius: $border-radius-1;
    background-color: transparent;
    transition: all 0.3s ease-in-out;

    &:focus,
    &:active {
      box-shadow: none !important;
      outline: none !important;
      color: inherit !important;
      background-color: transparent !important;
      border: solid 1px $secondary-color !important;
    }

    &:active {
      opacity: 0.9;
    }
  }

  &-input-donate {
    outline: none;
    border: solid 1px $gray;
    color: $gray;
    max-width: 30rem !important;
    border-radius: $border-radius-1;
    background-color: transparent;
    transition: all 0.3s ease-in-out;

    &:focus {
      box-shadow: none !important;
      outline: none !important;
      color: inherit !important;
      background-color: transparent !important;
      border: solid 1px $primary-color !important;
    }

    &:active {
      opacity: 0.9;
    }
  }

  &-label {
    font-size: $font-size-4;
    color: $primary-color;
    cursor: pointer;
  }

  &-input-amount {
    outline: none;
    border: none;
    color: $secondary-color;
    font-family: $secondary-font;
    text-align: center;
    font-size: $font-size-2;
    border-radius: $border-radius-1;
    background-color: transparent;
    transition: all 0.3s ease-in-out;
    font-weight: 500;

    &:focus,
    &:active {
      box-shadow: none !important;
      outline: none !important;
      color: $secondary-color;
      background-color: transparent !important;
      border: none;
    }

    &:active {
      opacity: 0.9;
    }
  }
}

textarea {
  &.enrichher-input-donate {
    max-height: 10rem;
    min-height: 5rem;
    resize: none;
    font-weight: 500;
  }
}