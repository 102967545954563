$primary-color: #ee3981;
$secondary-color: #354c73;
$secondary: #aeb3cb;
$secondary-2: #db6382;
$white: #fff;
$gray: #40403f;
$black: #101010;
$gray-light: #fafafa;
$font-size-1: 2.25em;
$font-size-2: 1.5em;
$font-size-3: 1.25em;
$font-size-4: 1em;
$font-size-5: 0.875em;
$sm-break: 600px;
$primary-font: "Kiona",sans-serif;

$secondary-font: "Lyon Display Web", sans-serif;

$border-radius-1: 24px;
