.enrichher {
  &-btn-v1 {
    outline: none;
    border: solid 1px $gray;
    color: $gray;
    background-color: transparent;
    transition: opacity 0.3s ease-in-out;

    &:focus,
    &:active,
    &:hover {
      box-shadow: none !important;
      outline: none !important;
      color: inherit !important;
      background-color: transparent !important;
      border-color: inherit !important;
    }

    &:active {
      opacity: 0.5;
    }
  }

  &-btn-v2 {
    outline: none;
    border: none !important;
    color: $white;
    background-color: $secondary-color;
    transition: opacity 0.3s ease-in-out;

    &:focus,
    &:active,
    &:hover {
      box-shadow: none !important;
      outline: none !important;
      color: $white !important;
      background-color: $secondary-color !important;
      border-color: inherit !important;
    }

    &:active {
      opacity: 0.7;
    }
  }

  &-btn-primary {
    outline: none;
    border: none !important;
    color: $white;
    background-color: $primary-color;
    transition: opacity 0.3s ease-in-out;

    &:focus,
    &:active,
    &:hover {
      box-shadow: none !important;
      outline: none !important;
      color: $white !important;
      background-color: $primary-color !important;
      border-color: inherit !important;
    }

    &:active {
      opacity: 0.7;
    }
  }
}

.block-load-more {
  display: flex;
  align-items: center;

  &:after,
  &:before {
    content: "";
    flex: 1;
    height: 1px;
    background-color: gray;
    opacity: .2;
  }
}

.btn-load-more {
  outline: none;
  color: $secondary-color !important;
  border: none !important;
  padding: .4rem 1rem !important;
  background-color: transparent !important;
  transition: opacity 0.3s ease-in-out;

  &:focus,
  &:active,
  &:hover {
    color: $secondary-color !important;
    box-shadow: none !important;
    outline: none !important;
    background-color: transparent !important;
    border-color: inherit !important;
  }

  &:active {
    opacity: 0.7;
  }
}

.btn-icon {
  outline: none;
  color: $secondary-color !important;
  border: none !important;
  padding: 0 !important;
  background-color: transparent !important;
  transition: opacity 0.3s ease-in-out;

  &:focus,
  &:active,
  &:hover {
    color: $secondary-color !important;
    box-shadow: none !important;
    outline: none !important;
    background-color: transparent !important;
    border-color: inherit !important;
  }

  &:active {
    opacity: 0.7;
  }
}

.btn-read-more {
  cursor: pointer;
  padding: 2rem 0;
  padding-right: 0.4rem;
  color: $secondary-color;
  font-weight: 500;

  &:hover {
    opacity: 0.8;
  }
}