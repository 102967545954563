.enrichher {
  &-navbar {
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .navbar-inner {
      max-width: 1200px;
      flex: 1;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .navbar-brand {
        max-width: 60%;
      }
    }
    .logo-desktop {
      width: calc(13rem + 20vw);
      max-width: 60%;
    }
    .logo-mobile {
      display: none;
      width: calc(15rem + 20vw);
      max-width: 100%;
    }
    @media screen and (max-width: $sm-break) {
      .logo-desktop {
        display: none;
      }
      .logo-mobile {
        display: inline;
      }
    }
  }
}
