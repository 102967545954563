body {
  font-family: $secondary-font,$primary-font, Arial, Helvetica, sans-serif;
  font-weight: 300;
  padding: 0;
  margin: 0;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;

  &::-webkit-scrollbar-track {
    background-color: #fff;
  }

  &::-webkit-scrollbar {
    width: 0px; /* add value if the iframe in App.js is removed*/
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $primary-color;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.4);
    opacity: 1;
    border-radius: 24px;
    &:hover {
      background-color: rgba($primary-color, 0.9);
    }
  }
}
@media (max-width: 600px) {
  html {
    font-size: 0.9em;
  }
}
.btn-round {
  border-radius: 500px;
}

.title-pink-1 {
  color: $primary-color;
  font-family: $secondary-font;
  font-size: $font-size-2;
  font-weight: 600;
}
.title-header-1 {
  color: $secondary-color;
  font-family: $primary-font;
  font-size: $font-size-1;
  font-weight: bold;
}

.text-secondary-1 {
  color: $black;
  font-family: $secondary-font;
  font-size: $font-size-4;
  font-weight: 400;
}
