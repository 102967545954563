.home-page {
  overflow: hidden;
  br {
    display: block;
    content: "";
    margin: calc(.5em + 2%) 0;
  }
}

.enrichher {
  &-header {
    position: relative;
    width: 100%;
    overflow: hidden;
    .header-image{
      &-desktop,&-mobile {
        width: 100%;
        max-height: 60vh;
        object-fit: cover;
        object-position: center left
      }
      &-mobile {
        display: none;
      }
    }


    @media screen and (max-width: $sm-break) {
      .header-image-desktop {
        display: none;
      }

      .header-image-mobile {
        display: block;
      }
    }
  }

  &-content {
    min-height: 60vh;
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: $gray-light;
  }

  &-about-text {
    min-height: 60vh;
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: $gray-light;

  }

  &-beneficiaries {
    .image-beneficiaries {
      height: auto;
      height: auto;
      padding: 0.5rem;
      width: 50%;

      img {
        max-width: 100%;
      }
    }
  }
}

.info-donations {
  .main-info {
    font-size: 1em;
    color: $black;
  }

  .amount {
    font-size: $font-size-2;
    color: $secondary-color;
    font-family: $secondary-font;
    font-weight: 500;
  }
}

.modal-donations {
  .link-register{
    margin: 2% 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    span {
      margin-bottom: 2%;
      font-weight: 500;
    }
    a,span {
      color: $secondary-color;
      text-align: center;
      line-height: 1.2em;
      font-size: $font-size-4;
      font-style: italic;
    }
   a {
     text-decoration: underline;
     font-weight: bold;
     transition: opacity .3s ease-in-out;
     &:hover{
       opacity: .8;
     }
     &:active {
       opacity: 1;
     }
   }

  }

  .edit-amount {
    color: $primary-color;
    font-size: $font-size-4;
    padding: 0 .5rem;
    display: flex;
    justify-content: center;
  }

  .modal-content {
    br {
      display: none;
    }
  }

  .separator {
    height: 1px;
    background-color: gray;
    opacity: .3;
    width: 100%;
    margin: 1rem auto;
  }

  .modal-footer,
  .modal-header {
    border: none;
  }

  .modal-header {
    position: relative;

    &:after {
      content: "";
      width: calc(100% - 2rem);
      height: 1px;
      background-color: gray;
      position: absolute;
      bottom: 0;
      left: 50%;
      opacity: .3;
      transform: translate(-50%, 0);
    }
  }


  .modal-header {
    margin: 0;

    padding: 1rem;
  }

  .close {
    color: $gray;
    font-size: 2.3em;
  }

  .modal-title {
    color: $secondary-color;
    font-family: $primary-font;
    font-size: $font-size-1;
    font-weight: bold;
  }

  *:focus {
    outline: none;
    border: none;
  }
}

.content-share {
  position: relative;

  .alert {
    position: absolute;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
    top: -40%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -100%);
  }
}
