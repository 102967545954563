.spinner-donation {
  color: $primary-color;
  width: 5rem;
  height: 5rem;
}
.load-page {
  height: 100vh;
  width: 100%;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
