.circular-chart {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 10rem;
  max-height: 250px;
  transform: rotate(-180deg);
  //opacity: 1;
  .circle-bg {
    fill: none;
    stroke: #eee;
    opacity: 0.5;
    stroke-width: 2;
  }
  .circle {
    fill: none;
    stroke-width: 2;
    stroke-linecap: round;
    stroke: $secondary-color;
    //transition: all 1s ease-in-out;
    //stroke-dasharray: 0 100;
  }
}
.container-percentage {
  position: relative;
  margin-bottom: 1.5rem;
  .container-image {
    position: absolute;
    top: 50%;
    left: 50%;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
    padding: 1rem;
    width: 6rem;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    .icon-image {
      width: 100%;
    }
  }
  .percentage {
    position: absolute;
    bottom: -1.2rem;
    left: 50%;
    transform: translate(-50%, 0);
    color: $black;
    font-size: 1em;
    font-weight: 500;
  }
}
